import { Vue, Component } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';


@Component({})
export default class EmailConfirmationPopUp extends Vue {
    private objScreenText: ScreenText = new ScreenText();

    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    close(){
      this.$emit('close', {id:'email-confirmation'});
    }

}