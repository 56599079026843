



























































import EmailConfirmationPopUp from '@/popupcomponents/emailconfirmationpopup/EmailConfirmationPopUp';
export default EmailConfirmationPopUp;
